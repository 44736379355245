.approvedtransaction_container {
    width: 100%;
    height: auto;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    padding: 30px 10px;
}

.approvedtransaction_wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    padding: 10px 30px;
}

.approvedtransaction_header {
    h1 {
        font-family: Raleway;
        font-style: normal;
        font-weight: 900;
        font-size: 24px;
        line-height: 20px;
        letter-spacing: -0.24px;
        font-feature-settings: "pnum" on, "lnum" on;
        color: #4e4e4e;
    }
}

.search_download_container {
    display: flex;
    justify-content: space-between;

    .search_container {
        background: #fdfdff;
        border: 1px solid #828282;
        box-sizing: border-box;
        border-radius: 10px;
        width: 300px;
        height: 40px;
        padding: 7px 10px 7px 0px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        input {
            width: 90%;
            height: 100%;
            border: none;
            outline: none;
            padding: 0px 10px;
            font-family: Raleway;
        }
        i {
            color: #4e4e4e;
            font-size: 1rem;
        }
    }

    button {
        border: 1px solid #0934f6;
        box-sizing: border-box;
        border-radius: 4px;
        padding: 10px 20px;
        background-color: #ffffff;
        font-family: Raleway;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        color: #0804dd;
    }

    button i {
        color: #0804dd !important;
    }
}
