.marketer_container {
    width: 100%;
    height: auto;
    background: url(../images/mainlibertyg.svg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    padding: 30px 10px;
}

.marketer_wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    padding: 10px 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.marketer_card_container {
    width: 60%;
    height: 700px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.marketer_card_header {
    h1 {
        font-family: Raleway;
        font-style: normal;
        font-weight: 500;
        font-size: 30px;
        line-height: 20px;
        letter-spacing: -0.24px;
        color: #ffffff;
    }
    span {
        text-decoration: underline;
    }
}

.marketer_card_content {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
}

.borrowers,
.totalamount,
.walletbalance {
    img {
        width: 70px;
        height: 70px;
    }
    p {
        color: #ffffff;
    }
    h1 {
        color: #ffffff;
    }
}

.sendmsg_btn {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    button {
        background: #0934f6;
        border: 1px solid #0934f6;
        border-radius: 4px;
        width: 30%;
        padding-top: 15px;
        padding-bottom: 15px;
        font-family: Raleway;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        letter-spacing: 0.16px;
        font-feature-settings: "pnum" on, "lnum" on;

        color: #ffffff;
    }
}
