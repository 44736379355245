#resetPassword_container {
    width: 100%;
    height: 100vh;
    min-height: 100%;
    background: url(../images/loginbg.svg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;
}

.resetPassword_wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
}
.resetPasswordCard_container {
    width: 35%;
    height: auto;
    padding: 30px 50px;
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    position: relative;
    background: #ffffff;
    border: 1px solid #c4c4c4;
    box-sizing: border-box;
    border-radius: 8px;

    // justify-content: center;
}

.title_login {
    h1 {
        font-family: Raleway;
        font-style: normal;
        font-weight: 900;
        font-size: 40px;
        line-height: 59px;
        color: #4c1961;
    }
}

.reset_passwordheader {
    display: flex;
    align-items: start;

    i {
        font-size: 24px;
        color: #4e4e4e;
        margin-top: 5px;
    }
}

.reset_passwordtext {
    h6 {
        font-family: Poppins;
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        color: #4e4e4e;
        margin: 0 !important;
    }
    p {
        font-family: Poppins;
        font-style: normal;
        font-weight: 400;
        font-size: 0.7rem;
        line-height: 36px;
        color: #4e4e4e;
        margin: 0 !important;
    }
}
.form_container_resetPassword {
    width: 100%;
    position: relative;
    margin-top: 20px;
}

.inputfield_container {
    margin-top: 20px;
}

.inputfield_title {
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    color: #828282;
    margin: 0 !important;
}

.send_btn {
    width: 100%;
    border: none;
    padding-top: 10px;
    padding-bottom: 15px;
    background: #0934f6;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
    border-radius: 10px;
    font-family: Raleway;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    color: #ffffff;

    a {
        text-decoration: none;
        color: #ffffff;
    }
}

.reset_password {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;

    a {
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #5d5fef;
        text-decoration: none;
    }
}
