.users_container {
    width: 100%;
    height: auto;
    background: url(../images/mainlibertyg.svg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    padding: 30px 10px;
}

.users_wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    padding: 10px 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.users_card_container {
    width: 60%;
    height: 700px;
    overflow-x: auto;
}

.users_card_header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h1 {
        color: #ffffff;
        font-family: Raleway;
        font-style: normal;
        font-weight: 500;
        font-size: 30px;
        line-height: 20px;
        text-align: center;
        letter-spacing: -0.24px;
        color: #ffffff;
    }
}

.headerSearchContainer {
    background: #fdfdff;
    border: 1px solid #828282;
    box-sizing: border-box;
    border-radius: 10px;
    width: 250px;
    height: 35px;
    padding: 5px;
    display: flex;
    align-items: center;

    input {
        width: 90%;
        height: 100%;
        border: none;
        outline: none;
        font-family: Raleway;
        font-style: normal;
        font-weight: 500;
        font-size: 0.8rem;
        line-height: 15px;
        letter-spacing: 0.16px;
        color: #000000;
    }

    input::placeholder {
        color: #939393;
    }

    i {
        margin-left: 10px;
    }
}

.users_table_container {
    width: 100%;
    height: 700px;
    background-color: #ffffff;
    padding: 20px 10px;
}

.border_around {
    border: 1px solid #82828296;
}
