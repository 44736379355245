.pendingcashback_container {
    width: 100%;
    height: auto;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    padding: 30px 10px;
}

.pendingcashback_wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    padding: 10px 30px;
}

.pendingtransaction_header {
    h1 {
        font-family: Raleway;
        font-style: normal;
        font-weight: 900;
        font-size: 24px;
        line-height: 20px;
        letter-spacing: -0.24px;
        font-feature-settings: "pnum" on, "lnum" on;
        color: #4e4e4e;
    }
}

.search_download_container {
    display: flex;
    justify-content: space-between;

    .search_container {
        background: #fdfdff;
        border: 1px solid #0804dd;
        box-sizing: border-box;
        border-radius: 10px;
        width: 300px;
        height: 40px;
        padding: 7px 10px 7px 0px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        input {
            width: 90%;
            height: 100%;
            border: none;
            outline: none;
            padding: 0px 10px;
            font-family: Raleway;
        }
        i {
            color: #0804dd;
            font-size: 1rem;
        }
    }

    button {
        border: 1px solid #0934f6;
        box-sizing: border-box;
        border-radius: 4px;
        padding: 10px 20px;
        background-color: #ffffff;
        font-family: Raleway;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        color: #0804dd;
    }

    button i {
        color: #0804dd !important;
    }
    .upload_csv_btn {
        background: #f6093c !important;
        border: none;
        margin-right: 20px;
        color: #ffffff !important;

        i {
            color: #ffffff !important;
        }
    }
    .approveall_btn {
        background: #0934f6 !important;
        border: none;
        margin-left: 20px;
        color: #ffffff !important;
    }
}

.approve_btn {
    background: #02ab46;
    border-radius: 3px;
    border: none;
    outline: none;
    font-family: Raleway;
    font-style: normal;
    font-weight: 450;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.16px;
    font-feature-settings: "pnum" on, "lnum" on;
    color: #ffffff;
    padding: 10px;
}
