@font-face {
    font-family: Lato;
    src: url(../font/Lato-Regular.ttf);
}
@font-face {
    font-family: Raleway;
    src: url(../font/Raleway-Regular.ttf);
}
@font-face {
    font-family: Lato;
    src: url(../font/Lato-Regular.ttf);
}
@font-face {
    font-family: Poppins;
    src: url(../font/Poppins-Regular.ttf);
}

#login_container {
    width: 100%;
    height: 100vh;
    min-height: 100%;
    background: url(../images/loginbg.svg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;
}

.login_wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
}
.login_card_container {
    width: 70%;
    height: auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    // justify-content: center;
}

.title_login {
    h1 {
        font-family: Raleway;
        font-style: normal;
        font-weight: 900;
        font-size: 40px;
        line-height: 59px;
        color: #4c1961;
    }
}

.form_container {
    width: 40%;
    position: relative;
}
.input-field {
    width: 100%;
    background: #ebebeb;
    border-radius: 20px;
    margin: 10px 0;
    height: 60px;
    display: grid;
    grid-template-columns: 15% 85%;
    padding: 0 0.4rem;
    position: relative;
    outline: none;
    margin-bottom: 20px;
}

.input-passwordfield {
    max-width: 100%;
    width: 100%;
    background: #ebebeb;
    border-radius: 20px;
    margin: 10px 0;
    height: 55px;
    display: grid;
    grid-template-columns: 15% 70% 15%;
    padding: 0 0.4rem;
    position: relative;
    outline: none;
    margin-bottom: 20px;
}
.input-passwordfield input {
    outline: none;
    border: none;
    background: none;
}

.input-passwordfield .icon___ {
    text-align: center;
    line-height: 55px;
    color: #000000;
    transition: 0.5s;
    font-size: 1.1rem;
}

.input-field .icon___ {
    text-align: center;
    line-height: 55px;
    color: #000000;
    transition: 0.5s;
    font-size: 1.1rem;
}

.input-field input {
    background: none !important;
    outline: none !important;
    border: none !important;
    line-height: 1 !important;
    font-weight: 300 !important;
    color: #333 !important;
    font-family: Raleway;
    font-size: 13px;
}

/* Change the white to any color */
.input-field input:-webkit-autofill,
.input-field input:-webkit-autofill:hover,
.input-field input:-webkit-autofill:focus,
.input-field input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #ebebeb inset !important;
}

.input-passwordfield input:-webkit-autofill,
.input-passwordfield input:-webkit-autofill:hover,
.input-passwordfield input:-webkit-autofill:focus,
.input-passwordfield input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #ebebeb inset !important;
}

.input-field select {
    background: none !important;
    outline: none !important;
    border: none !important;
    line-height: 1 !important;
    font-weight: 300 !important;
    color: #333 !important;
    font-family: Raleway;
    font-size: 13px;
}
.input-field option {
    background: #f0f0f0 !important;
    outline: none !important;
    border: none !important;
    line-height: 1 !important;
    font-weight: 300 !important;
    color: #333 !important;
    font-family: Raleway;
    font-size: 13px;
}

.input-field input::placeholder {
    color: #828282 !important;
    font-weight: 300;
    font-family: Raleway;
    font-size: 13px !important;
}

.input-passwordfield input::placeholder {
    color: #828282 !important;
    font-weight: 300;
    font-family: Raleway;
    font-size: 13px !important;
}

.login_btn {
    width: 100%;
    border: none;
    padding-top: 15px;
    padding-bottom: 15px;
    background: #0934f6;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
    border-radius: 10px;
    font-family: Raleway;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    color: #ffffff;
    margin-top: 10px;
}

.forgot_password {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;

    a {
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #5d5fef;
        text-decoration: none;
    }
}

.footnote {
    display: flex;
    margin-top: 100px;
    align-items: center;
    justify-content: center;

    .seperator {
        width: 10rem;
        height: 1px;
        background-color: rgb(0, 0, 0);
    }

    a {
        margin: 0px 20px;
        text-decoration: none;
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        color: #0934f6;
    }
}

// modal css
#loadingModal {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7) !important;
    z-index: 1000;
    display: none;
}

.loadingModalWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loadingModalCard {
    width: 30%;
    height: 40%;
    border-radius: 10px;
    background: url(../images/modalbg.svg);
    display: flex;
    align-items: center;
    justify-content: center;

    h1 {
        color: #ffffff;
        font-family: Raleway;
    }
}

// multiple repayment modal
#singleRepaymentModal {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7) !important;
    z-index: 900;
    display: none;
}

#multipleRepaymentModal {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7) !important;
    z-index: 1000;
    display: none;
}

#selectedRepaymentModal {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7) !important;
    z-index: 1000;
    display: none;
}

#deleteRepaymentModal {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7) !important;
    z-index: 900;
    display: none;
}

.multipleRepaymentModalWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.multipleRepaymentModalCard {
    width: 40%;
    height: 40%;
    border-radius: 10px;
    background: url(../images/modalbg.svg);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 20px 50px;

    h1 {
        color: #ffffff;
        font-family: Raleway;
        text-align: center;
        font-size: 1.5rem;
    }
}

.multiplerepay_buttoncontainer {
    display: flex;
    margin-top: 6rem;

    button {
        padding: 15px 25px;
        border: none;
        border-radius: 10px;
        font-size: 1rem;
    }
    .decline_btn {
        margin-right: 20px;
        background-color: #ffffff;
        color: #0934f6;
        font-family: Raleway;
        border: 1px solid #f60958 !important;
    }
    .continue_btn {
        margin-left: 20px;
        background-color: #0934f6;
        font-family: Raleway;
        color: #ffffff;
    }
}
