.pendingtransaction_container {
    width: 100%;
    height: auto;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    padding: 30px 10px;
}

.pendingtransaction_wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    padding: 10px 30px;
}

.pendingtransaction_header {
    h1 {
        font-family: Raleway;
        font-style: normal;
        font-weight: 900;
        font-size: 24px;
        line-height: 20px;
        letter-spacing: -0.24px;
        font-feature-settings: "pnum" on, "lnum" on;
        color: #4e4e4e;
    }
    .search_container {
        background: #fdfdff;
        border: 1px solid #0804dd;
        box-sizing: border-box;
        border-radius: 10px;
        width: 300px;
        height: 40px;
        padding: 7px 10px 7px 0px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        input {
            width: 90%;
            height: 100%;
            border: none;
            outline: none;
            padding: 0px 10px;
            font-family: Raleway;
        }
        i {
            color: #0804dd;
            font-size: 1rem;
        }
    }
}

.table_container {
    width: 100%;
    height: 600px;
    margin-top: 20px;
    padding: 10px;
    position: relative;
    overflow: auto;
}

.span_table {
    width: 100%;
}

.ui.table thead th {
    cursor: auto;
    background: #dbe1ff !important;
    border-radius: 4px;
    font-family: Raleway;
    color: #0804dd !important;
    font-style: normal;
    font-weight: 900;
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 0.16px;
    font-feature-settings: "pnum" on, "lnum" on;
    color: #202020;
    text-align: center !important;
    border: none !important;
}
.ui.table td {
    padding: 0.78571429em 0.78571429em;
    text-align: center !important;
    font-family: Raleway !important;
    font-style: normal;
    font-weight: 400;
    font-size: 14px !important;
    line-height: 15px;
    letter-spacing: 0.16px !important;
    color: #000000;
}

.status_pendingtrans {
    background: #dbe1ff;
    border-radius: 3px;
    padding: 10px 20px;
    border: none;
    color: #0934f6;

    i {
        margin-right: 10px;
    }
}

.delete_pendingtrans {
    background: #c52301;
    border-radius: 3px;
    padding: 10px 20px;
    border: none;
    color: #ffffff;

    i {
        margin-right: 10px;
    }
}

.approve_button_pending {
    background: #2dc653;
    border-radius: 3px;
    padding: 10px 20px;
    border: none;
    color: #ffffff;

    i {
        margin-right: 10px;
    }
}
.templatetransactionalTableHeader {
    background-color: #dbe1ff !important;
    border: none !important;
    height: 30px !important;
}

.status_color {
    color: #c52301 !important;
}

.reapprove_btn {
    background: #dbe1ff;
    border-radius: 3px;
    border: none;
    outline: none;
    font-family: Raleway;
    font-style: normal;
    font-weight: 450;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.16px;
    font-feature-settings: "pnum" on, "lnum" on;
    color: #0934f6;
    padding: 10px;
}
.view_btn {
    background: #e9ebee;
    border-radius: 3px;
    border: none;
    outline: none;
    font-family: Raleway;
    font-style: normal;
    font-weight: 450;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.16px;
    color: #000000;
    padding: 10px;
    margin-left: 20px;

    i {
        margin-left: 5px;
    }
}

.approve_selected_btn {
    background: #0934f6 !important;
    border: none;
    margin-left: 20px;
    color: #ffffff !important;
    display: none;
}

// loader modal tables
#loadingModaltable {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7) !important;
    z-index: 900;
    display: block;
}

.button_flex {
    display: flex;
}

// .upload_csv_btn{

// }

// CSV INPUT STYLE
#uploadCSVmodal {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7) !important;
    z-index: 1000;
    display: none;
}
.uploadCSVModalWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.uploadCSVModalCard {
    width: 50%;
    height: auto;
    border-radius: 10px;
    background: url(../images/modalbg.svg);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 20px 50px;

    h1 {
        color: #ffffff;
        font-family: Raleway;
        text-align: center;
        font-size: 1.5rem;
    }
}

.wrapper {
    width: 50%;
    background: #fff;
    border-radius: 5px;
    padding: 30px;
    box-shadow: 7px 7px 12px rgba(0, 0, 0, 0.05);
    margin: 0 auto;
    margin-top: 10px;
    margin-bottom: 10px;
    display: block;
}
.wrapper header {
    color: #6990f2;
    font-size: 27px;
    font-weight: 600;
    text-align: center;
}
.wrapper .form_container {
    width: auto;
    height: 167px;
    display: flex;
    cursor: pointer;
    margin: 30px 0;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 5px;
    border: 2px dashed #6990f2;
}
.form_container :where(i, p) {
    color: #6990f2;
}
.form_container i {
    font-size: 50px;
}
.form_container p {
    margin-top: 15px;
    font-size: 16px;
}
section .row {
    margin-bottom: 10px;
    background: #e9f0ff;
    list-style: none;
    padding: 15px 20px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
section .row i {
    color: #6990f2;
    font-size: 30px;
}
section .details span {
    font-size: 14px;
}
.progress-area .row .content {
    width: 100%;
    margin-left: 15px;
}
.progress-area .details {
    display: flex;
    align-items: center;
    margin-bottom: 7px;
    justify-content: space-between;
}
.progress-area .content .progress-bar {
    height: 6px;
    width: 100%;
    margin-bottom: 4px;
    background: #fff;
    border-radius: 30px;
}
.content .progress-bar .progress {
    height: 100%;
    width: 0%;
    background: #6990f2;
    border-radius: inherit;
}
.uploaded-area {
    max-height: 232px;
    overflow-y: scroll;
}
.uploaded-area.onprogress {
    max-height: 150px;
}
.uploaded-area::-webkit-scrollbar {
    width: 0px;
}
.uploaded-area .row .content {
    display: flex;
    align-items: center;
}
.uploaded-area .row .details {
    display: flex;
    margin-left: 15px;
    flex-direction: column;
}
.uploaded-area .row .details .size {
    color: #404040;
    font-size: 11px;
}
.uploaded-area i.fa-check {
    font-size: 16px;
}

.uploadCSVbutton {
    background: #dbe1ff;
    border-radius: 3px;
    border: none;
    outline: none;
    font-family: Raleway;
    font-style: normal;
    font-weight: 450;
    font-size: 17px;
    line-height: 15px;
    letter-spacing: 0.16px;
    font-feature-settings: "pnum" on, "lnum" on;
    color: #0934f6;
    padding: 15px 60px;
    margin-top: 20px;
}

#loadingModalcard_container {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7) !important;
    z-index: 1000;
    display: none;
}

.loadingModalbox {
    width: 40%;
    height: 40%;
    border-radius: 10px;
    background: url(../images/modalbg.svg);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 20px 50px;

    h1 {
        color: #ffffff;
        font-family: Raleway;
        text-align: center;
        font-size: 1.5rem;
    }
}
