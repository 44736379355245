.Navigation_container {
    width: 100%;
    height: auto;
    background-color: #eaeaea;
    overflow-x: scroll;
    overflow-y: scroll;
    padding: 20px 0px 20px 0px;
    position: relative;
}
.Navigation_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 20px;
}

.Navigation_repaymentcard {
    width: 254px;
    height: 195px;
    background: #000000;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(196, 196, 196, 0.12);
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    padding: 10px 20px;
    text-decoration: none;
    margin-left: 2rem;

    img {
        width: 50px;
        height: 50px;
    }
    h1 {
        color: #ffffff;
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 20px;
        text-align: center;
        letter-spacing: -0.24px;
        font-feature-settings: "pnum" on, "lnum" on;
        color: #ffffff;
        margin-top: 50px;
    }
    i {
        margin-right: 10px;
    }
}

.Navigation_repaymentcard:focus {
    background: #ffffff;
}

.Navigation_pendingtransaction {
    width: 254px;
    height: 195px;
    background: #ffffff;
    border: 2px solid #ffffff;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(196, 196, 196, 0.12);
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    padding: 10px 20px;
    text-decoration: none;
    margin-left: 2rem;

    img {
        width: 50px;
        height: 50px;
    }
    h6 {
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 20px;
        letter-spacing: -0.24px;
        font-feature-settings: "pnum" on, "lnum" on;
        color: #4e4e4e;
        margin: 0 !important;
        margin-top: 30px !important;
    }
    h1 {
        font-family: Poppins;
        font-style: normal;
        font-weight: 800;
        font-size: 20px;
        line-height: 20px;
        letter-spacing: -0.24px;
        font-feature-settings: "pnum" on, "lnum" on;
        color: #4e4e4e;
        margin: 0 !important;
        margin-top: 20px !important;
    }
    i {
        margin-right: 10px;
    }
}
.Navigation_approvedtransaction {
    width: 254px;
    height: 195px;
    background: #ffffff;
    border: 2px solid #ffffff;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(196, 196, 196, 0.12);
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    padding: 10px 20px;
    text-decoration: none;
    margin-left: 2rem;

    img {
        width: 50px;
        height: 50px;
    }
    h6 {
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 20px;
        letter-spacing: -0.24px;
        font-feature-settings: "pnum" on, "lnum" on;
        color: #4e4e4e;
        margin: 0 !important;
        margin-top: 30px !important;
    }
    h1 {
        font-family: Poppins;
        font-style: normal;
        font-weight: 800;
        font-size: 20px;
        line-height: 20px;
        letter-spacing: -0.24px;
        font-feature-settings: "pnum" on, "lnum" on;
        color: #4e4e4e;
        margin: 0 !important;
        margin-top: 20px !important;
    }
    i {
        margin-right: 10px;
    }
}
.Navigation_users {
    width: 254px;
    height: 195px;
    background: #ffffff;
    border: 2px solid #ffffff;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(196, 196, 196, 0.12);
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    padding: 10px 20px;
    text-decoration: none;
    margin-left: 2rem;

    img {
        width: 50px;
        height: 50px;
    }
    h6 {
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 20px;
        letter-spacing: -0.24px;
        font-feature-settings: "pnum" on, "lnum" on;
        color: #4e4e4e;
        margin: 0 !important;
        margin-top: 30px !important;
    }
    h1 {
        font-family: Poppins;
        font-style: normal;
        font-weight: 800;
        font-size: 20px;
        line-height: 20px;
        letter-spacing: -0.24px;
        font-feature-settings: "pnum" on, "lnum" on;
        color: #4e4e4e;
        margin: 0 !important;
        margin-top: 20px !important;
    }
    i {
        margin-right: 10px;
    }
}
.Navigation_marketing {
    width: 254px;
    height: 195px;
    background: #ffffff;
    border: 2px solid #ffffff;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(196, 196, 196, 0.12);
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    padding: 10px 20px;
    text-decoration: none;
    margin-left: 2rem;
    margin-right: 1rem;

    img {
        width: 50px;
        height: 50px;
    }
    h6 {
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 20px;
        letter-spacing: -0.24px;
        font-feature-settings: "pnum" on, "lnum" on;
        color: #4e4e4e;
        margin: 0 !important;
        margin-top: 30px !important;
    }
    h1 {
        font-family: Poppins;
        font-style: normal;
        font-weight: 800;
        font-size: 20px;
        line-height: 20px;
        letter-spacing: -0.24px;
        font-feature-settings: "pnum" on, "lnum" on;
        color: #4e4e4e;
        margin: 0 !important;
        margin-top: 20px !important;
    }
    i {
        margin-right: 10px;
    }
}
