.loanee_container {
    width: 100%;
    height: auto;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    padding: 30px 10px;
}

.loanee_wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    padding: 10px 30px;
}

.pendingtransaction_header {
    h1 {
        font-family: Raleway;
        font-style: normal;
        font-weight: 900;
        font-size: 24px;
        line-height: 20px;
        letter-spacing: -0.24px;
        font-feature-settings: "pnum" on, "lnum" on;
        color: #4e4e4e;
    }
}

.view_loanee_btn {
    background: #dbe1ff;
    border-radius: 3px;
    border: none;
    outline: none;
    font-family: Raleway;
    font-style: normal;
    font-weight: 450;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.16px;
    font-feature-settings: "pnum" on, "lnum" on;
    color: #0934f6;
    padding: 10px;

    i {
        margin-left: 5px;
    }
}
