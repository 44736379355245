.header_container {
    width: 100%;
    height: 20vh;
    background-color: #000000;
}

.header_wrapper {
    height: 100%;
    margin-top: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding: 0px 40px;

    h1 {
        margin: 0px !important;
        color: #ffffff;
        font-family: Raleway;
        font-style: normal;
        font-weight: 700;
        font-size: 30px;
        line-height: 15px;
        letter-spacing: 0.16px;
        font-feature-settings: "pnum" on, "lnum" on;
        color: #ffffff;
    }
}

.others {
    display: flex;
    align-items: center;
    p {
        color: #ffffff;
        font-family: Raleway;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
    }
    i {
        color: #ffffff;
        font-weight: 700;
        font-size: 20px;
        margin: 0px 30px;
        transition: 1s ease-in-out;
        cursor: pointer;
    }

    i:hover {
        background-color: #ffffff;
        border-radius: 50%;
        color: #000000;
        font-weight: 700;
        font-size: 20px;
        margin: 0px 30px;
        padding: 20px;
    }
}

.navigation {
    width: auto;
    background-color: black;

    img {
        width: 50px;
        border-radius: 50px;
        float: left;
    }
    .logout {
        font-size: 0.8em;
        font-family: Raleway !important;
        position: relative;
        right: -18px;
        bottom: -4px;
        overflow: hidden;
        letter-spacing: 3px;
        opacity: 0;
        transition: opacity 0.45s;
        -webkit-transition: opacity 0.35s;
    }

    .button {
        text-decoration: none;
        float: right;
        padding: 12px;
        margin: 15px;
        color: white;
        width: 25px;
        background-color: black;
        transition: width 0.35s;
        -webkit-transition: width 0.35s;
        overflow: hidden;
        cursor: pointer;
        font-family: Nunito !important;
    }

    .button:hover {
        width: 150px;
    }

    .button:hover .logout {
        opacity: 0.9;
    }

    .button {
        text-decoration: none;
    }
}
