.Repayment_container {
    width: 100%;
    height: auto;
    background: url(../images/mainlibertyg.svg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    padding-bottom: 100px;
}

.Repayment_card {
    width: 90%;
    height: 100%;
    background-color: #ffffff;
    position: relative;
    padding: 50px 20px;
}
.repayment_card_title {
    padding: 0px 100px;
    margin-top: 20px;
    h1 {
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 20px;
        letter-spacing: -0.24px;
        font-feature-settings: "pnum" on, "lnum" on;
        color: #000000;
    }
}
.repayment_form {
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    justify-content: space-evenly;
}

.form_section1 {
    width: 40%;
}
.form_section2 {
    width: 40%;
}
.form_input_container {
    margin-top: 1rem;
    width: 100%;

    p {
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
        letter-spacing: -0.24px;
        font-feature-settings: "pnum" on, "lnum" on;
        color: #646464;
        margin: 0px !important;
        margin-bottom: 10px !important;
        text-align: left !important;
    }
    input {
        width: 80%;
        height: 40px;
        border: 1px solid #0804dd4f;
        box-sizing: border-box;
        border-radius: 10px;
        padding: 10px;
        outline: none;
        font-family: Raleway;
    }
    select {
        width: 80%;
        height: 50px;
        border: 1px solid #0804dd4f;
        box-sizing: border-box;
        border-radius: 10px;
        padding: 10px;
        outline: none;
        font-family: Raleway;
    }
}

.postrepaymentbtn {
    margin-left: 7%;
    margin-top: 2rem;
    button {
        background: #0934f6;
        border-radius: 5px;
        background: #0934f6;
        padding: 10px 30px;
        font-family: Raleway;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        letter-spacing: 0.16px;
        font-feature-settings: "pnum" on, "lnum" on;
        color: #ffffff;
        border: none;
    }
}
